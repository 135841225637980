import React from "react";
import './Footer.css';

function Footer(){
    return (
        <footer className="p-4 text-center z-0"
        style={{ borderTop: "solid 4px var(--black)"}}
        >
            <h1>
                Stránku vytvořil <b>Jan Soukeník</b>
            </h1>
            <h2>
                Obrázky od <a href="https://www.freepik.com/free-photo/smiley-woman-cleaning-window_7798324.htm#fromView=author&page=1&position=22&uuid=30553c4a-b118-4134-ab3f-7c40c00c3052"><b>freepiku</b></a>
            </h2>
        </footer>
    )
}

export default Footer; 
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import BurgerMenu from '../components/BurgerMenu';
import Footer from '../components/Footer';
import './Layout.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Layout() {
    return (

        <>
        <div className='h-screen flex flex-col'>
            <Navbar/>
            <BurgerMenu/>
            <main className='flex-grow'>
                <Outlet />
            </main>
            <Footer />
        </div>
        <ToastContainer position='top-center'/>
        </>
    );
  }

export default Layout;
import './Contact.css'
import {SecondarySubmitButton} from "../../components/Buttons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Recaptcha from 'react-recaptcha'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Home(){
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        let code = searchParams.get('code');
        if (code === '200') {
            toast.success('Email byl úspěšně odeslán. Ozvu se vám co nejdříve!')
        } else if (code === '400') {
            toast.warning('Nebyly zadány všechny potřebné údaje!')
        } else if (code === '406') {
            toast.warning('Recaptcha nebyla vyplněna správně!')
        } else if (code === '407') {
            toast.warning('Kontakt na vás je zadán špatně!')    
        } else if (code === '500'){
            toast.error('Nastala chyba při odesílaní! Zkuste to prosím později.')
        }
    })

    let defaultMessage = `Dobrý den,
Chci se s vámi spojit ohledně vašich služeb.`;


    return (
        <article className="flex flex-col md:flex-row justify-between p-4 md:p-24 pt-20 gap-4">
            <div className="md:w-1/3 text-xl">
                <h1 className="text-4xl font-medium p-2">Kontaktujte te mě</h1>
                <p>Máte-li jakékoliv dotazy, připomínky nebo návrhy, neváhejte mě kontaktovat. Ráda vám pomohu!</p>
                <p>Telefonní číslo: <b><a href="tel:+420737434242" className='contact'>+420 737 434 242</a></b></p>
                <p>Email: <b><a href="mailto:sochorcova.martina@seznam.cz" className='contact'>sochorcova.martina@seznam.cz</a></b></p>

                <div className="border-2 my-4" style={{borderColor: "var(--secondary)"}}/>
            
                <h1 className="text-4xl font-medium p-2">Pracovní doba</h1>
                <p>Jsem vám k dispozici v následujících hodinách:</p>
                <ul className="pl-8 list-disc">
                    <li>Pondělí - Pátek: 9:00 - 17:00</li>
                    <li>Sobota: 10:00 - 14:00</li>
                    <li>Neděle: Zavřeno</li>
                </ul>
            </div>
            <div className="md:w-1/3 text-xl">
            <h1 className="text-4xl font-medium p-2">Napište mi mail</h1>
                <form className="flex flex-col gap-4" action='/api/sendMail' method='POST'>
                    <textarea className='p-4' rows={5} id="message" name="message" defaultValue={defaultMessage} placeholder='Zpráva...' required/>
                    <input className='p-4' type="text" id="name" name="name" placeholder='Jméno...' required/>
                    <input className='p-4' type="email" id="email" name="email" placeholder='Email...' required/>
                    <input className='p-4' type="tel" id="phone" name="phone" placeholder='Telefoní číslo...' />
                    <Recaptcha
                        sitekey="6Lf3mBwqAAAAAO_2IPQv06GwfMnCGeaqt7N-RIMa"
                        render="explicit"
                        action='submit_form'
                    />
                    <div className='flex justify-center'>
                    <SecondarySubmitButton children={"Odeslat"}/>
                    </div>
                </form>
            </div>
        </article>
    )
}

export default Home;
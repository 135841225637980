import { PrimaryButtonNav, SecondaryButtonNav } from "../../components/Buttons";
import CleanLady from "../../assets/cleanLady.svg";
import {ReactComponent as Corner} from "../../assets/corner.svg";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Home(){
    const location = useLocation();
    const navigate = useNavigate();


    // If i have querie redirect to contact page
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('code')){
            navigate("/kontakt?" + searchParams.toString())
        }
    })


    return (
        <article className="flex flex-col md:flex-row md:space-between gap-30 max-h-dvh px-4 items-center text-center justify-center pt-20">
            <div className="fixed bottom-0 right-0 hidden md:flex">
                <Corner/>
            </div>
            <div className="flex flex-col items-center text-center justify-center md:w-1/2">
                <h1 className="text-4xl font-bold p-2">
                    Úklid bytových prostor Brno
                </h1>
                <h2 className="text-3xl font-bold ">Martina Sochorcová</h2>
                <p className="text-xl px-8 py-4">Uklid? Žádný problém! Se mnou můžete být jisti, že vaše prostory budou vždy bez poskvrnky. 
                    Nabízím spolehlivý a nadstandardní úklid, který splní i ty nejnáročnější požadavky za výhodné a příznivé ceny.</p>
                <div className="flex flex-col items-center text-center justify-center p-8 gap-8 md:flex-row">
                    <SecondaryButtonNav url="/kontakt">Začněmě</SecondaryButtonNav>
                    <PrimaryButtonNav url="/o-nas">Zjistit víc</PrimaryButtonNav>
                </div>
            </div>
            <div className="flex justify-center items-center hidden md:flex md:w-1/2 z-0">
                    <img src={CleanLady} alt="Vector of cleaning lady" className="md:w-3/5"/>
            </div>
        </article>
    )
}

export default Home;
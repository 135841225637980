import React from "react";
import './Navbar.css';
import { NavLink } from 'react-router-dom';

function Navbar(){
    return (
        <nav className="relative hidden md:flex flex-row text-2xl font-semibold justify-between p-4"
        style={{ borderBottom: "solid 4px var(--black)"}}>
                <h1><NavLink to="/"
                className={({ isActive }) => (isActive ? "link-active" : "link")}>
                    <i class="fa-solid fa-house pr-2"/>
                    Domov
                    </NavLink>
                </h1>
                <ul className="flex flex-row text-start justify-evenly gap-4 md:gap-12">
                    <li>
                        <NavLink to="/o-nas"
                        className={({ isActive }) => (isActive ? "link-active" : "link")}>
                        <i className="fa-solid fa-question pr-2" />
                        Jak pracujeme
                    </NavLink>
                    </li>
                    <li>
                        <NavLink to="/kontakt"
                        className={({ isActive }) => (isActive ? "link-active" : "link")}>
                        <i className="fa-solid fa-address-book pr-2" />
                        Kontakt
                        </NavLink>
                        </li>
                </ul>
        </nav>
    )
}

export default Navbar; 
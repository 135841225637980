import './Button.css'
import { NavLink } from 'react-router-dom';


function PrimaryButtonNav({ children, url}) {
  return (
    <NavLink to={url} className="font-bold text-2xl p-2 text-center w-32 btn"
    style={{ backgroundColor: "var(--primary)", borderRadius: '3rem', border:"solid 2px var(--black)"}}
    >
      {children}
    </NavLink>
  )
}

function PrimaryButton({ children, fnc}) {
  return (
    <button onClick={fnc} className="font-bold text-2xl p-2 text-center w-32 btn"
    style={{ backgroundColor: "var(--primary)", borderRadius: '3rem', border:"solid 2px var(--black)"}}
    >
      {children}
    </button>
  )
}

function SecondaryButtonNav({ children, url}) {
  return (
    <NavLink to={url} className="font-bold text-2xl p-4 text-center w-50 btn"
    style={{ backgroundColor: "var(--secondary)", borderRadius: '3rem', border:"solid 3px var(--black)"}}
    >
      {children}
    </NavLink>
  )
}

function SecondaryButton({ children, fnc}) {
  return (
    <button onClick={fnc} className="font-bold text-2xl p-4 text-center w-50 btn"
    style={{ backgroundColor: "var(--secondary)", borderRadius: '3rem', border:"solid 3px var(--black)"}}
    >
      {children}
    </button>
  )
}

function SecondarySubmitButton({ children}) {
  return (
    <button type="submit" className="font-bold text-2xl p-4 text-center w-50 btn"
    style={{ backgroundColor: "var(--secondary)", borderRadius: '3rem', border:"solid 3px var(--black)"}}
    >
      {children}
    </button>
  )
}

export { PrimaryButtonNav, PrimaryButton, SecondaryButtonNav, SecondaryButton, SecondarySubmitButton }
import "./AboutUs.css";
import eq from '../../assets/pack-surface-cleaning-products.webp';
import fwindows from '../../assets/4windows-clean.svg';
import Brno from '../../assets/Brno.webp';

function AboutUs(){

    return (
        <article className="flex flex-col justify-between md:pt-4 ">
                <h1 className="text-3xl text-center font-semibold p-4">O nás</h1>

                <div className="p-4 md:px-32 md:py-16">
                    <h2 className="text-xl font-medium">Kdo jsem?</h2>
                    <span className="text-l">Jsem Martina Sochorcová OSVČ na trhu jsem nově a specializuji se na úklid společných prostor v bytových domech. 
                        Navzdory své novosti jsem spolehlivá, mám plno zkušeností a nabízím své služby za příznivé ceny.
                        Mojí základní filosofií je poskytovat kvalitní úklidové služby, které jsou cenově dostupné pro všechny.</span>
                </div>
            
                <div className="flex flex-col md:flex-row justify-between p-4 md:px-32 md:py-16 odd">
                    <div className="flex flex-col md:w-1/2 justify-center">
                        <h2 className="text-xl">Co uklízím</h2>
                        <span>Nabízím úklid společných bytových prostor jako například schody a chodby</span>
                        <div className="pt-2">
                            <h3 className="">Takový úklid může zahrnovat:</h3>
                            <ul className="pl-8 list-disc">
                                <li className="text-base p-2">Vytírání podlahových ploch</li>
                                <li className="text-base p-2">Úklid schodiště, stírání zábradlí</li>
                                <li className="text-base p-2">Odstraňovaní pavučin</li>
                                <li className="text-base p-2">Stírání povrchu parapetů, topných těles, poštovních schránek</li>
                                <li className="text-base p-2">Mytí vchodových dveří</li>
                                <li className="text-base p-2">Úklid kabiny výtahu</li>
                                <li className="text-base p-2">Úklid sklepních prostorů</li>
                                <li className="text-base p-2">Mytí oken</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex md:w-1/2 justify-center">
                        <img src={fwindows} alt="Vector of Services in four windows" />
                    </div>
                </div>
            
                <div className="flex flex-col md:flex-row justify-between p-4 md:px-32 md:py-16">
                    <div className="flex md:w-1/2 justify-center">
                        <img src={eq} alt="Vector of cleaning tools"/>
                    </div>
                    <div className="flex flex-col md:w-1/2 justify-center">
                        <h2 className="text-xl font-medium p-2">Jak postupuji</h2>
                        <ul className="pl-8 list-decimal">
                            <li className="text-l p-2">Kontaktujte nás</li>
                            <li className="text-l p-2">Domluvíme se na termínu</li>
                            <li className="text-l p-2">Přijedu k vám</li>
                            <li className="text-l p-2">Uklidím</li>
                            <li className="text-l p-2">Užívejte si čistotu!</li>
                            
                        </ul>
                        <p className="text-l">Také si s můžete domluvit pravidelný úklid</p>
                    </div>
                </div>
                            
                <div className="flex flex-col md:flex-row justify-between p-4 md:px-32 md:py-16 odd">
                <div className="flex flex-col md:w-2/3 justify-center">
                    <h2 className="text-xl font-medium">Kde uklízíme</h2>
                        <span className="text-l">V danou chvíli nabízíme naše služby <b>pouze v Brně</b>, ale je možná domluva, pokud se nacházíte v okolí Brna</span>  
                    </div>
                    <div className="flex md:w-3/5 justify-center p-16">
                        <img src={Brno} alt="Namesti Svobody Square in Brno"/>
                    </div>
                </div>
        </article>
    )
}

export default AboutUs;
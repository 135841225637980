import React from "react";
import { slide as Menu } from 'react-burger-menu'
import { NavLink } from 'react-router-dom';

class BurgerMenu extends React.Component {
    render () {
        var styles = {
            bmBurgerButton: {
              position: 'fixed',
              width: '36px',
              height: '30px',
              left: '30px',
              top: '30px'
            },
            bmBurgerBars: {
              background: '#333333'
            },
            bmBurgerBarsHover: {
              background: '#a90000'
            },
            bmCrossButton: {
              height: '24px',
              width: '24px'
            },
            bmCross: {
              background: '#bdc3c7'
            },
            bmMenuWrap: {
              position: 'fixed',
              height: '100%'
            },
            bmMenu: {
              background: '#ffffd4',
              padding: '2.5em 1.5em 0',
              fontSize: '1.5em'
            },
            bmMorphShape: {
              fill: '#ffffd4'
            },
            bmItemList: {
              color: '#333333',
            },
            bmOverlay: {
              background: 'rgba(0, 0, 0, 0.3)'
            },
            bmItem: {
              padding: '0.8em'
            }
          }

        return (
        <Menu styles={styles} burgerButtonClassName={ "md:hidden" }>
                    {/* <a> */}
                    <NavLink to="/"
                        className={({ isActive }) => (isActive ? "link-active" : "link") + "p-4"}>
                            <i className="fa-solid fa-house pr-4 w-1/6"/>
                            Domov
                    </NavLink>
                    {/* </a>
                    <a> */}
                    <NavLink to="/o-nas"
                        className={({ isActive }) => (isActive ? "link-active" : "link") + "p-4"}>
                            <i className="fa-solid fa-question pr-4 w-1/6" />
                            O nás
                    </NavLink>
                    {/* </a>
                    <a> */}
                    <NavLink to="/kontakt"
                        className={({ isActive }) => (isActive ? "link-active" : "link") + "p-4"}>
                            <i className="fa-solid fa-address-book pr-4 w-1/6" />
                            Kontakt
                    </NavLink>
                    {/* </a> */}
        </Menu>
    );
  }
}

export default BurgerMenu;